<template>
  <div>
    <v-container>
      <v-row>
        <div class="d-flex justify-center wrapper-menu">
          <div class="inner-wrapper-menu">
            <h1>My Diary</h1>

            <ul class="filter">
              <li v-bind:class="{ 'active': filter === 'asc' }"><a href="javascript:;" @click="getDiary('asc')">Asc</a></li>
              <li v-bind:class="{ 'active': filter === 'desc' }"><a href="javascript:;" @click="getDiary('desc')">Desc</a></li>
              <li v-bind:class="{ 'active': filter === 'lasts' }"><a href="javascript:;" @click="getDiary('lasts')">Lasts</a></li>
            </ul>
          </div>
        </div>
      </v-row>

      <v-container style="height: 400px;" v-if="!diary.length">
        <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col class="text-subtitle-1 text-center" cols="12" v-if="!loading">There are no content to show.</v-col>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
        </v-row>
      </v-container>
      <v-row justify="center" class="mt-10 mb-6 diary--descriptions" v-if="diary.length && !loading">
        <v-timeline>
          <v-timeline-item
            v-for="(item, key) in diary"
            :key="`diary-${key}`"
            large
          >
            <template v-slot:icon>
              <v-icon
                :class="{'alternate-icon-small': !mdiBookOpenVariant}"
                class="mx-auto icon-info-date"
                dark
              >
                {{ mdiBookOpenVariant }}
              </v-icon>
            </template>
            <template v-slot:opposite>
              <span>{{ formatDate(item.selected_date.split('T')[0]) }}</span>
            </template>
            <v-card class="elevation-2">
              <v-card-title class="text-h5">
                {{ item.title }}

                <v-icon
                  :class="{'alternate-icon-small': !mdiSquareEditOutline}"
                  class="mx-auto icon-edit"
                  @click="$store.commit('showDiaryModal', item)"
                >
                  {{ mdiSquareEditOutline }}
                </v-icon>
                <v-icon
                  :class="{'alternate-icon-small': !mdiTrashCan}"
                  class="mx-auto icon-trash"
                  @click="deleteItem(key)"
                >
                  {{ mdiTrashCan }}
                </v-icon>
              </v-card-title>
              <v-card-text class="item-description">
                <div v-show="deleting[key]" class="wrapper-action-btns">
                  <v-row>
                    <v-col
                      class="text-subtitle-1 text-center"
                      cols="12" v-if="loading"
                    >
                      <v-progress-linear
                        color="primary accent-4"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>
                   <v-col v-if="!loading"
                      class="text-subtitle-1 text-center"
                      cols="12"
                    >
                      Are you sure you want to delete this file?
                    </v-col>
                    <v-col cols="12" class="justify-content-center wrapper-buttons-center text-center" v-if="!loading">
                      <v-btn color="primary" @click="submitDelete(item.id)">Delete</v-btn>&nbsp;
                      <v-btn @click="$set(this.deleting, key, false);">Cancel</v-btn>
                    </v-col>
                  </v-row>
                </div>

                <div v-html="item.description"></div>
                <span class="created_at">
                  Created at: {{ formatDate(new Date(item.created_at).toLocaleDateString('en-CA')) }} - {{ new Date(item.created_at).toLocaleTimeString('en-CA') }}
                </span>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mdiChevronDown, mdiCloudUpload, mdiBookOpenVariant, mdiSquareEditOutline, mdiTrashCan } from '@mdi/js';

export default {
  name: "MyDiary",
  directives: {
  },
  components: {
  },
  data: () => ({
    loading: true,
    deleting: [],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    /* Icons */
    mdiChevronDown,
    mdiCloudUpload,
    mdiBookOpenVariant,
    mdiSquareEditOutline,
    mdiTrashCan,
    /* ---- */
    endpoint: process.env.VUE_APP_ROOT_API,
    user: {
      id: 0,
    },
    diary: [],
    filter: 'desc',

  }),
  computed: {
    saved() {
      return this.$store.state.diary;
    }
  },
  watch: {
    saved(val) {
      this.getDiary('desc');
    }
  },
  mounted() {
    this.checkLogin();
    this.getDiary('desc');
  },
  methods: {
    deleteItem(key) {
      this.$set(this.deleting, key, true);
    },
    checkLogin() {
      this.$store.commit('setLogin', true);
      this.active = true;
      this.logged =  true;
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${this.months[parseInt(month, 10) - 1]} ${day} ${year}`
    },
    getDiary(order) {
      this.loading = true;
      this.filter = order;
      this.diary = [];
      axios.get(`${this.endpoint}/getDiary?order=${order}`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        this.loading = false;
        this.diary = response.data.content;
        this.deleting = Array.from({length: this.diary.length}, (_, i) => false);
      })
      .catch(() => { /* ERROR */ });
    },
    submitDelete(id) {
      this.loading = true;
      axios.post(`${this.endpoint}/deleteDiary`, {
        id: id,
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        this.loading = false;
        this.getDiary('desc');
      });
    },
  }
};
</script>

<style scoped lang="scss">
h1 {
  margin-top: 30px;
}
.wrapper-menu {
  flex: 1;
  position: relative;
  .inner-wrapper-menu {
    text-align: center;
  }
  .v-btn {
    margin-bottom: 20px;
  }

  .v-btn:not(:last-child) {
    margin-right: 10px;
  }
}

.switch-menu {
  position: absolute;
  top: -66px;
  right: 0;
  width: 150px;
  text-align: center;
}

.icon-trash, .icon-edit {
  position: absolute;
  right: 2%;
  top: 22px;
  cursor: pointer;
}

.icon-edit {
  right: 7%;
}

.item-description {
  position: relative;
  min-height: 110px;
}

.wrapper-action-btns {
  position: absolute;
  background: #fff;
  height: 100%;
  width: 96%;
}

.v-timeline-item {
  min-width: 1000px;
}

.v-progress-linear {
  width: 90% !important;
  margin: 20px auto !important;
}

.filter {
  position: absolute;
  right: 0;
  li {
    float: left;
    list-style: none;
    margin: 0 10px;
    a {
      text-decoration: none;
    }
    &.active {
      a {
        text-decoration: underline;
      }
    }
  }
}

.created_at {
  font-size: 11px;
  position: absolute;
  right: 10px;
  bottom: 8px;
}
</style>
